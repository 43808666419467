import { useState } from 'react';
import { GrUpdate } from 'react-icons/gr';
import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Td,
  Text,
  Tooltip,
  Tr,
  useToast,
} from '@chakra-ui/react';
import * as internalToolsTxnApi from 'api/transactions';
import { TOAST_STATUS_SUCCESS } from 'constants/constants';
import { formatTransactionTime } from 'modules/merchantinfo/utils/utils';
import { showError, showToast } from 'utils/notifications';

export default function TxnStep({
  stepLevelProgress,
  getPayoutSessionStepLevelProgress,
  transactionId,
  payoutSessionPurposes,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isPurposeModalOpen, setIsPurposeModalOpen] = useState(false);
  const [selectedPurpose, setSelectedPurpose] = useState('');
  const [isPurposeLoading, setIsPurposeLoading] = useState(false);
  const toast = useToast();

  const retriggerPayoutSessionStepEvent = async () => {
    setIsLoading(true);
    try {
      await internalToolsTxnApi.retriggerPayoutSessionStepEvent({
        is_event_processed: stepLevelProgress?.is_processed,
        event_id: stepLevelProgress?.event_id,
      });
      toggleConfirmModal();
      showToast(
        toast,
        'Success',
        `Successfully retriggered event - ${stepLevelProgress?.type} for transaction - ${transactionId}.`,
        TOAST_STATUS_SUCCESS
      );
    } catch (error) {
      showError(toast, 'Error retriggering event.', error);
    } finally {
      setIsLoading(false);
      getPayoutSessionStepLevelProgress();
    }
  };

  const updateTransactionPurpose = async () => {
    setIsPurposeLoading(true);
    try {
      await internalToolsTxnApi.updatePayoutPurpose(transactionId, {
        purpose: selectedPurpose,
      });
      togglePurposeModal();
      showToast(toast, 'Success', `Successfully updated transaction purpose to ${selectedPurpose}.`, TOAST_STATUS_SUCCESS);
    } catch (error) {
      showError(toast, 'Error updating transaction purpose.', error);
    } finally {
      setIsPurposeLoading(false);
    }
  };

  const toggleConfirmModal = () => {
    setIsConfirmModalOpen((prev) => !prev);
  };

  const togglePurposeModal = (shouldReset = true) => {
    setIsPurposeModalOpen((prev) => !prev);
    if (shouldReset) {
      setSelectedPurpose('');
    }
  };

  const handlePurposeChange = (event) => {
    setSelectedPurpose(event?.target?.value);
    if (event?.target?.value) {
      togglePurposeModal(false);
    }
  };

  const timestamp = new Date(stepLevelProgress?.created_at);
  const time = timestamp?.toLocaleTimeString();
  const failureReason = stepLevelProgress?.failure_reason;
  const showPurposeDropdown =
    stepLevelProgress?.source_partner_code === 'LAYER2' && stepLevelProgress?.dest_partner_code === 'BENEFICIARY';

  return (
    <>
      <Tr py='2' my='2' borderY='1px solid'>
        <Td color='gray'>{formatTransactionTime(stepLevelProgress?.created_at)}</Td>
        <Td color='gray'>{time}</Td>
        <Td textTransform='uppercase'>{stepLevelProgress?.type}</Td>
        <Td>{stepLevelProgress?.category_id}</Td>
        <Td>{stepLevelProgress?.source_partner_code}</Td>
        <Td>{stepLevelProgress?.dest_partner_code}</Td>
        <Td>{Math.abs(stepLevelProgress?.source_amount)}</Td>
        <Td>{stepLevelProgress?.source_currency}</Td>
        <Td>{stepLevelProgress?.dest_currency}</Td>
        <Td>{stepLevelProgress?.txn_status ?? 'INITIATED'}</Td>
        <Td>
          <Tooltip label={failureReason} bg='gray'>
            {failureReason?.length > 15 ? `${failureReason?.substring(0, 15)}...` : failureReason}
          </Tooltip>
        </Td>
        <Td>
          <Flex gap={2}>
            {stepLevelProgress?.can_retry && (
              <Tooltip label='Retrigger'>
                <Button
                  size='sm'
                  width='fit-content'
                  onClick={() =>
                    stepLevelProgress?.is_processed ? toggleConfirmModal() : retriggerPayoutSessionStepEvent()
                  }
                  leftIcon={<GrUpdate />}
                  isLoading={isLoading}
                />
              </Tooltip>
            )}
            {showPurposeDropdown && (
              <Select
                size='sm'
                width='auto'
                placeholder='Select Purpose'
                value={selectedPurpose}
                onChange={handlePurposeChange}
              >
                {payoutSessionPurposes?.map((purpose) => (
                  <option key={purpose?.id} value={purpose?.id}>
                    {purpose?.value}
                  </option>
                ))}
              </Select>
            )}
          </Flex>
        </Td>
      </Tr>
      <Modal isOpen={isConfirmModalOpen} onClose={toggleConfirmModal} closeOnOverlayClick={false} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Are you sure ?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Retrigger already success event</Text>
          </ModalBody>
          <ModalFooter>
            <Flex gap={2}>
              <Button
                isLoading={isLoading}
                onClick={retriggerPayoutSessionStepEvent}
                size='sm'
                colorScheme='blue'
                variant='solid'
              >
                Yes
              </Button>
              <Button variant='outline' mr={3} onClick={toggleConfirmModal} size='sm'>
                No
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isPurposeModalOpen} onClose={togglePurposeModal} closeOnOverlayClick={false} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Purpose Update</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Are you sure you want to update the transaction purpose to {selectedPurpose}?</Text>
          </ModalBody>
          <ModalFooter>
            <Flex gap={2}>
              <Button
                isLoading={isPurposeLoading}
                onClick={updateTransactionPurpose}
                size='sm'
                colorScheme='blue'
                variant='solid'
              >
                Yes
              </Button>
              <Button variant='outline' mr={3} onClick={togglePurposeModal} size='sm'>
                No
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
