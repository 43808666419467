import React, { useState } from 'react';
import { Button, HStack, Text, VStack } from '@chakra-ui/react';
import UpdateKycStatusModal from 'components/KycRfi/UpdateKycStatusModal';

const KycRfiPreview = ({
  sumsub_applicant_id,
  entity_name,
  sumsub_kyc_status,
  kyc_status,
  entityId,
  fetchKycDetails,
  sumsub_profile_url,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const isButtonVisible = kyc_status !== 'active' && kyc_status !== 'failed';

  return (
    <HStack
      border='1px solid'
      borderColor='gray.200'
      p={6}
      mt={6}
      borderRadius='md'
      background='white'
      spacing={6}
      alignItems='flex-start'
      flexWrap='wrap'
    >
      <VStack align='flex-start' spacing={3} flex={1}>
        <HStack gap={1}>
          <Text fontWeight='bold'>Applicant Id:</Text>
          <Text>{sumsub_applicant_id || 'N/A'}</Text>
        </HStack>
        <HStack gap={1}>
          <Text fontWeight='bold'>Entity Name:</Text>
          <Text>{entity_name || 'N/A'}</Text>
        </HStack>
      </VStack>
      <VStack align='flex-start' spacing={2} flex={1}>
        <HStack gap={1}>
          <Text fontWeight='bold'>Sumsub KYC Status:</Text>
          <Text color={sumsub_kyc_status === 'PASS' ? 'green.500' : 'red.500'}>{sumsub_kyc_status || 'N/A'}</Text>
        </HStack>
        <HStack gap={1} w='full'>
          <Text fontWeight='bold'>Internal KYC Status:</Text>
          <Text color={kyc_status === 'active' ? 'green.500' : 'red.500'} textTransform={'UPPERCASE'}>
            {kyc_status || 'N/A'}
          </Text>
          {isButtonVisible && (
            <Button
              colorScheme='blue'
              size='sm'
              variant='outline'
              px={6}
              py={1}
              fontWeight='bold'
              onClick={() => setIsOpen(true)}
            >
              Update Status
            </Button>
          )}
        </HStack>
      </VStack>
      <UpdateKycStatusModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        kyc_status={kyc_status}
        sumsub_kyc_status={sumsub_kyc_status}
        entityId={entityId}
        fetchKycDetails={fetchKycDetails}
        sumsub_profile_url={sumsub_profile_url}
      />
    </HStack>
  );
};

export default KycRfiPreview;
