import { useEffect, useState } from 'react';
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import * as internalToolsTxnApi from 'api/transactions';
import TxnStep from 'modules/merchantinfo/components/TxnStep';
import { TXN_STEP_COLUMNS } from 'modules/merchantinfo/constants/constants';
import LoadingErrorWrapper from 'components/LoadingErrorWrapper/LoadingErrorWrapper';

export default function CheckTxnStepLevelProgress({ isOpen, onClose, transaction }) {
  const [stepLevelProgressList, setStepLevelProgressList] = useState([]);
  const [payoutSessionPurposes, setPayoutSessionPurposes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const getPayoutSessionStepLevelProgress = async () => {
    setIsLoading(true);
    try {
      const response = await internalToolsTxnApi.getPayoutSessionStepLevelProgress(transaction?.id);

      setStepLevelProgressList(response?.data?.data?.payout_session_step_events ?? []);
    } catch (error) {
      setError(error);
    }
    setIsLoading(false);
  };

  const getPayoutSessionPurposes = async () => {
    try{
      const response = await internalToolsTxnApi.getPayoutPurposes();

      setPayoutSessionPurposes(response?.data?.data ?? []);
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    transaction && getPayoutSessionStepLevelProgress();
    transaction && getPayoutSessionPurposes();
  }, [transaction]);

  return (
    <Drawer isOpen={isOpen} placement='right' onClose={onClose} size='xl'>
      <DrawerOverlay />
      <DrawerContent maxWidth='1380px'>
        <DrawerCloseButton />
        <DrawerHeader>Transaction Step Level Progress</DrawerHeader>

        <DrawerBody>
          <LoadingErrorWrapper isLoading={isLoading} errorTitle='Error' error={error}>
            <Box mb={4} bg='gray.100' borderRadius='8px' p={3}>
              <Text fontSize='lg'>Transaction Id:- {transaction?.id}</Text>
            </Box>
            <Box overflowX='auto' maxWidth='100%' style={{ borderWidth: '2px', borderRadius: '18px' }}>
              <Table variant='simple'>
                <Thead>
                  <Tr>
                    {TXN_STEP_COLUMNS.map((column) => (
                      <Th key={column}>{column}</Th>
                    ))}
                  </Tr>
                </Thead>
                <Tbody>
                  {stepLevelProgressList.map((stepLevelProgress) => (
                    <TxnStep
                      stepLevelProgress={stepLevelProgress}
                      key={stepLevelProgress?.event_id}
                      getPayoutSessionStepLevelProgress={getPayoutSessionStepLevelProgress}
                      transactionId={transaction?.id}
                      payoutSessionPurposes={payoutSessionPurposes}
                    />
                  ))}
                </Tbody>
              </Table>
            </Box>
          </LoadingErrorWrapper>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
