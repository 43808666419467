import { Flex, Text } from '@chakra-ui/react';
import KycRfiCard from 'components/KycRfi/KycRfiCard';

const RfiSection = ({ title, rfiList, emptyMessage, isSubmittedRfi }) => (
  <Flex
    direction='column'
    p={6}
    width='100%'
    border='1px solid'
    borderRadius='md'
    borderColor='gray.400'
    maxHeight='600px'
    overflow='auto'
  >
    {rfiList?.length > 0 ? (
      <Flex flexDirection='column' gap={3} alignItems='center' width='100%' height='100%'>
        <Text fontSize='xl' fontWeight='bold'>
          {title}
        </Text>
        <Flex flexDirection='column' gap={4} alignItems='center' overflow='auto' width='100%' height='100%'>
          {rfiList.map((rfi) => (
            <KycRfiCard key={rfi?.id} rfi={rfi} isSubmittedRfi={isSubmittedRfi} />
          ))}
        </Flex>
      </Flex>
    ) : (
      <Flex justifyContent='center' alignItems='center' height='100%' width='100%'>
        <Text fontSize='2xl' fontWeight='bold' color='gray.400'>
          {emptyMessage}
        </Text>
      </Flex>
    )}
  </Flex>
);

export default RfiSection;
