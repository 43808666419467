import React, { useState } from 'react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  useToast,
} from '@chakra-ui/react';
import { updateKycStatus } from 'api/internaltools';
import { KYC_STATUS_OPTIONS, SUMSUB_KYC_STATUS } from 'constants/kyc';
import { showError, showToast } from 'utils/notifications';

export default function UpdateKycStatusModal({
  isOpen,
  onClose,
  kyc_status,
  sumsub_kyc_status,
  sumsub_profile_url,
  entityId,
  fetchKycDetails,
}) {
  const [loading, setLoading] = useState(false);
  const [statusValue, setStatusValue] = useState('');

  const toast = useToast();

  const handleStatusChange = (e) => {
    setStatusValue(e.target.value);
  };

  const getKycStatusOptions = () => {
    if (sumsub_kyc_status === SUMSUB_KYC_STATUS.PASS) {
      return KYC_STATUS_OPTIONS.filter((status) => status.value !== kyc_status);
    }

    return KYC_STATUS_OPTIONS.filter((status) => status.value === 'failed' && status.value !== kyc_status);
  };

  const UpdateKycStatus = async () => {
    setLoading(true);
    const payload = {
      status: statusValue,
    };

    try {
      await updateKycStatus(payload, entityId);
      showToast(toast, 'KYC status updated successfully', 'KYC status has been updated successfully.', 'success');
      fetchKycDetails();
    } catch (error) {
      showError(
        toast,
        'Error updating KYC status',
        error?.response?.data?.message || 'An error occurred while updating KYC status.'
      );
    } finally {
      setLoading(false);
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={true} size='xl' isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex align='center' gap={2}>
            Update Internal KYC Status
          </Flex>{' '}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {sumsub_kyc_status === SUMSUB_KYC_STATUS.FAILED && (
            <Alert status='warning' borderRadius='md' mb={4}>
              <AlertIcon />
              <AlertDescription>
                To mark the KYC status as succeeded, please update the Sumsub KYC status to{' '}
                <Text as='strong' color='green.500'>
                  PASS
                </Text>{' '}
                first on the Sumsub dashboard.
              </AlertDescription>
            </Alert>
          )}
          <FormControl>
            <FormLabel color={'gray.500'} fontWeight={'bold'}>
              KYC Status
            </FormLabel>
            <Select
              value={statusValue}
              onChange={handleStatusChange}
              backgroundColor={'white'}
              placeholder='Select KYC Status'
              disabled={loading}
              color={'gray.500'}
            >
              {getKycStatusOptions().map((risk, idx) => (
                <option key={idx} value={risk.value}>
                  {risk.label}
                </option>
              ))}
            </Select>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Flex gap={2}>
            <Button
              size='sm'
              variant='outline'
              colorScheme='blue'
              onClick={() => window.open(sumsub_profile_url, '_blank')}
            >
              Go To Sumsub Dashboard
            </Button>
            <Button size='sm' variant='outline' onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme='blue'
              mr={3}
              size='sm'
              onClick={UpdateKycStatus}
              isLoading={loading}
              disabled={!statusValue || loading}
            >
              Update Status
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
