export const BY_ENTITY_ID = 'by Entity Id';

export const KYC_STATUS_OPTIONS = [
  {
    value: 'active',
    label: 'Active',
  },
  {
    value: 'failed',
    label: 'Failed',
  },
];

export const SUMSUB_KYC_STATUS = {
  PASS: 'PASS',
  FAILED: 'FAILED',
};
