import { useEffect, useState } from 'react';
import { Button, Checkbox, HStack, Text, useToast, VStack } from '@chakra-ui/react';
import { getRfiDocumentsInfo } from 'api/internaltools';
import { updateKycDetails } from 'api/kyc';
import { showError, showToast } from 'utils/notifications';
import KycRfiQuestion from 'components/KycRfi/KycRfiQuestion';
import LoadingErrorWrapper from 'components/LoadingErrorWrapper/LoadingErrorWrapper';

const formInitialState = {
  prompt: '',
  is_document_required: false,
  is_front_back_required: false,
  transaction_id: '',
  document_type: '',
  document_sub_type: [],
};

const KycRfiForm = ({ entityId, fetchKycDetails, kyc_status }) => {
  const [questions, setQuestions] = useState([formInitialState]);
  const [loading, setLoading] = useState(false);
  const [documentInfoLoading, setDocumentInfoLoading] = useState(false);
  const [documentInfoError, setDocumentInfoError] = useState(null);
  const [documentInfo, setDocumentInfo] = useState([]);
  const [isKycStatusUpdate, setIsKycStatusUpdate] = useState(true);

  const toast = useToast();

  const handleAddNewQuestion = () => {
    setQuestions((prev) => [...prev, formInitialState]);
  };

  const updateQuestion = (index, key, value) => {
    setQuestions((prev) => prev.map((question, i) => (i === index ? { ...question, [key]: value } : question)));
  };

  const handleDeleteQuestion = (index) => {
    if (questions.length === 1) return;
    setQuestions((prev) => prev.filter((_, i) => i !== index));
  };

  const handleSubmit = async () => {
    const hasEmptyPrompt = questions.some((question) => !question.prompt.trim());
    const hasInvalidDocument = questions.some(
      (question) =>
        question.is_document_required && (!question.document_type || question.document_sub_type.length === 0)
    );
    const hasInvalidSubType = questions.some(
      (question) =>
        (question.is_document_required && question.document_sub_type.includes('other')) ||
        question.document_sub_type.includes('')
    );

    if (hasEmptyPrompt) {
      showError(toast, 'Question prompt cannot be empty', '', 'Please fill all the questions');

      return;
    }

    if (hasInvalidDocument || hasInvalidSubType) {
      showError(
        toast,
        'Invalid document details',
        '',
        'If a document is required, please provide a valid type and subtype.'
      );

      return;
    }

    setLoading(true);
    const payload = {
      create_rfi_details: questions,
      is_update_kyc_status: isKycStatusUpdate,
    };

    try {
      await updateKycDetails(entityId, payload);
      setQuestions([formInitialState]);
      showToast(toast, 'RFI Request submitted successfully', 'RFI Request has been submitted successfully.', 'success');
      fetchKycDetails();
    } catch (error) {
      showError(toast, 'Error during submission', error);
      setDocumentInfoError(error);
    } finally {
      setLoading(false);
    }
  };

  const getRfiDocumentsDetails = async () => {
    setDocumentInfoLoading(true);
    try {
      const response = await getRfiDocumentsInfo();

      setDocumentInfo(response?.data?.data);
    } catch (error) {
      showError(toast, 'Error fetching document info', error);
    } finally {
      setDocumentInfoLoading(false);
    }
  };

  useEffect(() => {
    getRfiDocumentsDetails();
  }, []);

  return (
    <VStack
      gap={4}
      pt={8}
      px={8}
      pb={6}
      width={'100%'}
      border={'1px solid'}
      borderRadius={'md'}
      borderColor={'gray.400'}
      mt={8}
      maxHeight={'400px'}
    >
      <LoadingErrorWrapper isLoading={documentInfoLoading} error={documentInfoError}>
        <VStack width={'full'} overflow={'auto'} maxHeight={'275px'}>
          {questions?.map((question, index) => (
            <KycRfiQuestion
              key={index}
              question={question}
              updateQuestion={updateQuestion}
              index={index}
              handleDeleteQuestion={handleDeleteQuestion}
              isLastQuestion={index === questions?.length - 1}
              documentInfo={documentInfo}
            />
          ))}
        </VStack>
        <HStack justifyContent={'space-between'} width={'100%'}>
          <Button onClick={handleAddNewQuestion} width='fit-content'>
            + Add new question
          </Button>
          <HStack spacing={4}>
            {kyc_status === 'active' && (
              <Checkbox isChecked={isKycStatusUpdate} onChange={() => setIsKycStatusUpdate(!isKycStatusUpdate)}>
                <Text fontWeight='bold'>Update KYC Status to RFI Requested</Text>
              </Checkbox>
            )}
            <Button onClick={handleSubmit} width='fit-content' color='white' background='black' isLoading={loading}>
              Submit
            </Button>
          </HStack>
        </HStack>
      </LoadingErrorWrapper>
    </VStack>
  );
};

export default KycRfiForm;
