import { useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import { DeleteIcon } from '@chakra-ui/icons';
import { Box, Button, Checkbox, HStack, Input, Text, Textarea, VStack } from '@chakra-ui/react';

const KycRfiQuestion = ({
  index,
  question: { prompt, transaction_id, is_document_required, is_front_back_required },
  updateQuestion,
  handleDeleteQuestion,
  isLastQuestion,
  documentInfo,
}) => {
  const questionRef = useRef(null);
  const [selectedDocumentType, setSelectedDocumentType] = useState(null);
  const [subtypes, setSubtypes] = useState([]);
  const [selectedSubtype, setSelectedSubtype] = useState([]);
  const [otherSubtype, setOtherSubtype] = useState('');

  useEffect(() => {
    if (isLastQuestion) {
      questionRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [isLastQuestion]);

  const handleDocumentTypeChange = (selectedOption) => {
    setSelectedDocumentType(selectedOption);
    updateQuestion(index, 'document_type', selectedOption ? selectedOption.value : '');

    const document = documentInfo.find((doc) => doc.value === selectedOption?.value);

    const subtypeOptions = document ? [...document.subtypes, { value: 'other', label: 'Other' }] : [];

    setSubtypes(subtypeOptions);
    setSelectedSubtype([]);
    setOtherSubtype('');
  };

  const handleSubtypeChange = (selectedOptions) => {
    const selectedValues = selectedOptions ? selectedOptions.map((option) => option.value) : [];

    setSelectedSubtype(selectedOptions);

    if (selectedValues.includes('other')) {
      setOtherSubtype('');
    }
    updateQuestion(index, 'document_sub_type', selectedValues);
  };

  const handleOtherSubtypeChange = (e) => {
    const value = e.target.value;

    setOtherSubtype(value);

    const updatedSubtypes = selectedSubtype.filter((option) => option.value !== 'other').map((option) => option.value);

    updateQuestion(index, 'document_sub_type', [...updatedSubtypes, value]);
  };

  const handleIsDocumentRequiredChange = () => {
    setSelectedDocumentType(null);
    setSubtypes([]);
    setSelectedSubtype([]);
    setOtherSubtype('');
    updateQuestion(index, 'is_document_required', !is_document_required);
  };

  return (
    <Box
      ref={questionRef}
      border='1px solid'
      borderColor='gray.200'
      p={4}
      borderRadius='md'
      background='white'
      w='full'
    >
      <HStack gap={4} alignItems='start'>
        <VStack flex='1' align='stretch' spacing={4}>
          <Text fontWeight='bold'>Question {index + 1}</Text>
          <Textarea
            placeholder='Enter question'
            value={prompt}
            onChange={(e) => updateQuestion(index, 'prompt', e.target.value)}
          />
          <Input
            placeholder='Transaction ID (optional)'
            value={transaction_id}
            onChange={(e) => updateQuestion(index, 'transaction_id', e.target.value)}
          />
        </VStack>

        <VStack flex='1' align='stretch' spacing={4}>
          <HStack justifyContent='space-between' alignContent='center'>
            <Checkbox isChecked={is_document_required} onChange={handleIsDocumentRequiredChange}>
              Document Required
            </Checkbox>
            <Checkbox
              isChecked={is_front_back_required}
              onChange={() => updateQuestion(index, 'is_front_back_required', !is_front_back_required)}
              isDisabled={!is_document_required}
            >
              Both-Side Required
            </Checkbox>
            <Button
              onClick={() => handleDeleteQuestion(index)}
              variant='outline'
              colorScheme='red'
              size='sm'
              disabled={index === 0}
            >
              <DeleteIcon color='red.500' />
            </Button>
          </HStack>

          {is_document_required && (
            <>
              <Select
                placeholder='Select Document Type'
                options={documentInfo.map((doc) => ({ value: doc.value, label: doc.label }))}
                value={selectedDocumentType}
                onChange={handleDocumentTypeChange}
                isClearable
                styles={{
                  container: (base) => ({ ...base, backgroundColor: 'white' }),
                }}
                menuPosition='fixed'
              />

              <Select
                isMulti
                placeholder='Select Subtype'
                options={subtypes}
                value={selectedSubtype}
                onChange={handleSubtypeChange}
                styles={{
                  container: (base) => ({ ...base, backgroundColor: 'white' }),
                }}
                menuPosition='fixed'
                isDisabled={!selectedDocumentType}
              />

              {selectedSubtype.some((subtype) => subtype.value === 'other') && (
                <Textarea
                  placeholder='Enter other subtype'
                  maxLength={1000}
                  value={otherSubtype}
                  onChange={handleOtherSubtypeChange}
                  backgroundColor='white'
                />
              )}
            </>
          )}
        </VStack>
      </HStack>
    </Box>
  );
};

export default KycRfiQuestion;
